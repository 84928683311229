.postContainer {
    padding: 0px 50px;
}

.postSquare {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    font-family: "Source Code Pro", monospace;
    color: $white;
    text-shadow: 0 3px 3px rgba(0,0,0,0.5);

    .squareContent {
        background-image: linear-gradient(180deg, rgba(18,18,26,0) 0%, rgba(18,18,26,0.8) 54%);
    }

    h2 {
        font-size: calc(18px + (38 - 18) * ((100vw - 270px) / (1600 - 270)));
        font-weight: 700;
    }

    .sinkEmphasis {
        font-style: normal;

        --bg-size: 400%;
        --color-one: #FF2173;
        --color-two: #fff;
        background: linear-gradient(
                        90deg,
                        var(--color-one),
                        var(--color-two),
                        var(--color-two),
                        var(--color-one)
                    ) 0 0 / var(--bg-size) 100%;
        color: transparent;
        text-shadow: none;
        -webkit-background-clip: text;
        background-clip: text;
        animation: move-bg 8s infinite linear;
    }

    .readMore {
        padding: 0 5px;
        box-shadow: inset 0 0 0 0 white;
        transition: all 0.4s ease-in-out 0s;
    }

    &:hover{
        .readMore {
            text-shadow: none;
            box-shadow: inset 300px 0 0 0 white;
            color: #FF2173;
        }
    }
    



    // .readMore {
    //     padding: 0 5px;
    //     box-shadow: inset 0 0 0 0 white;
    //     transition: all 0.4s ease-in-out 0s;
    //     &:hover{
    //         box-shadow: inset -300px 0 0 0 white;
    //         color: #FF2173;
    //     }
    // }




    // &:hover {
    //     .readMore {
    //         color: #FF2173;
    //         display: inline-block;
    //         position: relative;
    //     }
    // }
}
