.about {
    background-color: rgba(18, 18, 26,1);

    .row {
        width: 100%;
        max-width: 1250px;
        margin: auto;

        position: relative;
        display: flex;
        justify-content: space-between;
        
        @media (max-width: $breakPoint) {
            flex-direction: column;
        }

        .column {
            width: 48%;
            position: relative;

            @media (max-width: $breakPoint) {
                width: 100%;
                position: relative;
            }

            .aboutText {
                font-family: "Source Code Pro", monospace;
            }

            .imgWrap {
                width: 100%;
                height: 100%;
                min-height: 350px;
                position: relative;

                @media (max-width: $breakPoint) {
                    margin-top: 25px;
                }

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: cover;
                    // position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}