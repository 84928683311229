.navbar {
    position: fixed;
    top: 0;
    z-index: 999;
  
    background: transparent;
    width: 100vw;
    height: 56px;
    transition-duration: .5s;

    font-family: "Source Code Pro", monospace;  
    color: $white;
  }
  
  .navbarActive {
    position: fixed;
    top: 0;
    z-index: 999;
  
    background-color: rgba(18, 18, 26,1);
    width: 100%;
    height: 56px;
    transition-duration: .5s;

    font-family: "Source Code Pro", monospace;
    color: $white;   
  }

  .siteName {
    font-family: "Playfair Display", serif;
  }

  .navLinks {
    margin: 3em;

    color: rgba(255,255,255,0.7);
    font-size: calc(38px + (72 - 38) * ((100vw - 270px) / (1600 - 270)));
    font-weight: 700;
    line-height: 2;

    @media (max-width: 450px) {
      margin: 1em;
    }
  } 
  
.wordmark {
  color: rgba(255,255,255,0.1) !important; 
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 24vw;

  text-transform: uppercase;
  text-align: center;
}