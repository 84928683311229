.footerBackground {
    width: 100%;
    height: 30vh;

    padding: 0px 20px;
    justify-content: space-between;

    background-image: linear-gradient(180deg, rgba(18,18,26,1) 19%, rgba(18,18,26,0.4) 74%), url(../assets/footer/sand.jpg); 
    background-size: cover; 
    background-position: top;

    font-family: "Source Code Pro", monospace;

    @media (max-width: 767px) {
        justify-content: end;
    }
}