.carousel {
    position: relative;
    z-index: 0;
    .swiper-pagination-bullet-active {
        background: $white;
    }
}

.slideWrap {
    min-height: 75vh;
    width: 100%;
    padding: 80px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    filter: grayscale(100%);
    transition: filter 3.5s;

    > * {
        opacity: 0;
        transform: translateY(55px);
        transition: all 0.4s;
    }

    @media (max-width: 760px) and (orientation: landscape) {
        min-height: 110vh;
        padding: 70px 20px;
    }

    @media (max-width: 760px) and (orientation: portrait) {
        min-height: 80vh;
        padding: 70px 20px;
    }
}

.textWrap {
    max-width: 900px;
    text-align: center;

    h2 {
        font-family: "Playfair Display", serif;
        font-size: 4.5em;
        line-height: 1.1;
        color: $white;
        margin-bottom: 10px;
        text-shadow: 4px 4px 6px rgba(0,0,0,1);

        @media (max-width: $breakPoint) {
            font-size: 2.5em;
        }
    }

    p {
        font-family: "Source Code Pro", monospace;
        font-size: 1em;
        color: $white;
        text-shadow: 1px 1px 5px rgba(0,0,0,1);
    }

    .btn {
        font-size: 1em;
        color: #fff;
        background-color: $primaryColor;
        display: inline-block;
        padding: 10px 30px;
        margin-top: 20px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: 0.5s;

        &:hover {
            letter-spacing: 4px;
        }
    }

    > * {
        opacity: 0;
        transform: translateY(-45px);
        transition: all 1s;
    }
}

.swiper-slide-active {
    .slideWrap {
        filter: grayscale(20%);

        > * {
            opacity: 1;
            transform: none;

            @for $i from 0 to 15 {
                &:nth-child(#{$i + 1}) {
                    transition-delay: $i * 0.1 + 0.3s;
                }
            }

            .btn { transition-delay: initial !important; }
        }
    }

    .textWrap {
        > * {
            opacity: 1;
            transform: none;

            @for $i from 0 to 15 {
                &:nth-child(#{$i + 1}) {
                    transition-delay: $i * 0.3 + 1s;
                }
            }
        }
    }
}