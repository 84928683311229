.wrapper {
    color: $white;
    font-family: "Source Code Pro", monospace;
    background-color: rgba(18, 18, 26,1);

    h3 {
        font-size: calc(14px + (18 - 14) * ((100vw - 270px) / (1600 - 270))) !important;
        font-weight: 700;
        text-shadow: 0 3px 5px rgba(0,0,0,0.5);
        text-align: center;
    }
}

.featured {
    .postSquare {
        height: 100% !important;
        .squareContent {
            background-image: linear-gradient(180deg, rgba(18,18,26,0) 30%, rgba(18,18,26,0.7) 94%);
        }
    }
}

.featuredBlogSqr {
    &:hover {
        .whitebtn{
            background-color: $background;
            border: 1px solid $background;
            letter-spacing: 4px;
        }
    }
}

.info {
    position: fixed;
    height: 40vh;
}




.btnDisplay {

    .whitebtn {
        font-size: .8em;
        color: $white;
        border: 1px solid $white;
        display: inline-block;
        padding: 8px 22px;
        margin-top: 20px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: 0.5s;
    }

    .redbtn {
        font-size: 1em;
        color: #fff;
        background-color: $primaryColor;
        display: inline-block;
        padding: 4px 14px;
        margin-top: 20px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: 0.5s;

        &:hover {
            letter-spacing: 4px;
        }
    }
}